import { format } from "date-fns";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { zoomInWithDelay } from "../animations/variants";
import Layout from "../components/layout";
import SEO from "../components/seo";

function BlogCard({ header, description, category, writer, date, url, img }) {
  const d = new Date(date);
  const formattedDate = format(d, "do LLLL yyyy");
  return (
    <div
      className="blog-card"
      onClick={() => {
        console.log("Card Clicked.", url);
        navigate(`/blog/${url}`);
      }}
    >
      <div
        className="blog-img"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="blog-content">
        <div className="blog-header">{header}</div>
        <div className="blog-description">{description}</div>

        <div className="blog-extra">
          <div className="blog-category">{category}</div>
          <div className="blog-writer">{writer}</div>
          <div className="blog-date">{formattedDate}</div>
        </div>
      </div>
    </div>
  );
}

BlogCard.defaultProps = {
  header: "Blog Header",
  description: "Blog Description",
  category: "CNC Machining",
  writer: "Shubham Rateria",
  date: "Febuary 21, 2020",
  url: "",
};

function BlogLanding({ pageContext }) {
  const { blogs } = pageContext;
  const [currentPage, setCurrentPage] = useState(0);
  const blogsPerPage = 5;
  const pageCount = Math.ceil(blogs.length / blogsPerPage);
  const [showingBlogs, setShowingBlogs] = useState(
    blogs.slice(0, blogsPerPage)
  );

  const changePage = (pageNumber) => {
    const toShow = blogs.slice(
      pageNumber * blogsPerPage,
      (pageNumber + 1) * blogsPerPage
    );
    setShowingBlogs(toShow);
  };

  return (
    <Layout>
      <SEO title="Custiv Blogs" />
      <div className="blogs">
        <div className="header-section">
          <div className="title">Custiv Blogs</div>
        </div>
        <div className="blogs-container">
          {showingBlogs.map((blog, index) => (
            <motion.div
              variants={zoomInWithDelay(0.2 + index * 0.2)}
              animate="show"
              initial="hidden"
            >
              <BlogCard
                header={blog.name}
                writer={blog.author}
                description={blog.summary}
                url={blog.url}
                date={blog.createdAt}
                key={index}
                img={blog.banner_image?.formats.small.url}
              />
            </motion.div>
          ))}
        </div>
        <ReactPaginate
          pageCount={pageCount}
          containerClassName={"pagination"}
          subContainerClass={"pages pagination"}
          activeClassName={"page-active"}
          onPageChange={(s) => {
            changePage(s.selected);
          }}
        />
      </div>
    </Layout>
  );
}

BlogLanding.defaultProps = {
  blogs: [
    {
      Name: "Blog Name",
      Summary: "Blog Summary",
      Author: "Blog Author",
      date: "",
      category: "",
      url: "#",
    },
    {
      Name: "Blog Name",
      Summary: "Blog Summary",
      Author: "Blog Author",
      date: "",
      category: "",
      url: "#",
    },
    {
      Name: "Blog Name",
      Summary: "Blog Summary",
      Author: "Blog Author",
      date: "",
      category: "",
      url: "#",
    },
    {
      Name: "Blog Name",
      Summary: "Blog Summary",
      Author: "Blog Author",
      date: "",
      category: "",
      url: "#",
    },
  ],
};

export default BlogLanding;
